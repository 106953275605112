import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import roLocale from "@fullcalendar/core/locales/ro"

import ColorHash from "color-hash"
import { Link } from "gatsby"
import React from "react"
import { RRule } from "rrule"
import Layout from "../components/layout"
import { Seo, useSiteMetadata } from "../components/seo"
import { Share } from "../components/share"
import ClientOnly from "../components/ClientOnly"

var colorHash = new ColorHash()

const Title = "Calendarul vânătorului"
const SelfUrl = "/calendar/"

function date(year, month, day) {
  return new Date(Date.UTC(year, month - 1, day))
}

const minDate = "2024-08-01"
const maxDate = "2025-05-01"

// Articolul 111. Zilele de sărbătoare nelucrătoare
// https://www.legis.md/cautare/getResults?doc_id=132680&lang=ro
//
// Doar cele relevante.
const sarbatori = [
  {
    name: "Naşterea lui Isus Hristos",
    date: date(2024, 12, 25),
  },
  {
    name: "Anul Nou",
    date: date(2025, 1, 1),
  },
  {
    name: "Naşterea lui Isus Hristos",
    date: date(2025, 1, 7),
  },
  {
    name: "Naşterea lui Isus Hristos",
    date: date(2025, 1, 8),
  },
  {
    name: "8 Martie",
    date: date(2025, 3, 8),
  },
]

const events = []

for (const sarbatoare of sarbatori) {
  events.push({
    title: sarbatoare.name,
    start: sarbatoare.date.toISOString().split("T")[0],
    display: "background",
    backgroundColor: "red",
  })
}

function rruleToEvents(title, rule, includeHolidays) {
  const color = colorHash.hex(title)

  const ruleOccurrences = [...rule.all()]
  let result = []

  if (includeHolidays) {
    for (const sarbatoare of sarbatori) {
      if (
        rule.options.dtstart.getTime() > sarbatoare.date.getTime() ||
        rule.options.until.getTime() < sarbatoare.date.getTime()
      ) {
        // Skip, holiday out of range.
        continue
      }

      let haveEvent = false
      for (const occurrence of ruleOccurrences) {
        if (occurrence.getTime() === sarbatoare.date.getTime()) {
          haveEvent = true
          break
        }
      }
      if (!haveEvent) {
        result.push({
          title,
          start: sarbatoare.date.toISOString().split("T")[0],
          borderColor: color,
          backgroundColor: color,
        })
      }
    }
  }

  for (const dt of ruleOccurrences) {
    const startDt = dt.toISOString().split("T")[0]

    result.push({
      title,
      start: startDt,
      borderColor: color,
      backgroundColor: color,
    })
  }

  return result
}

{
  const title = "Porumbel (gulerat, guguștiuc)"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 8, 17),
    until: date(2024, 10, 13),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Porumbel (gulerat)"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 10, 16),
    until: date(2025, 1, 15),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Prepeliță"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 8, 17),
    until: date(2024, 10, 13),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Rață și Lișiță"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 9, 14),
    until: date(2025, 1, 13),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Gâscă"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 10, 16),
    until: date(2025, 1, 13),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Fazan (în grup)"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 10, 2),
    until: date(2025, 1, 15),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Fazan (individual cu câini)" // miercuri cu caini
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE],
    tzid: "UTC",
    dtstart: date(2024, 10, 2),
    until: date(2025, 1, 15),
  })

  events.push(...rruleToEvents(title, rule))
}

{
  const title = "Sturz"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 10, 16),
    until: date(2025, 1, 15),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Iepure"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 11, 24),
    until: date(2025, 1, 5),
  })

  events.push(...rruleToEvents(title, rule))
}

{
  const title = "Vulpe și Șacal"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2025, 1, 15),
    until: date(2025, 3, 2),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Vulpe și Șacal (cu câini gonitori)"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 10, 2),
    until: date(2025, 3, 9),
  })

  events.push(...rruleToEvents(title, rule, true))
}

{
  const title = "Vulpe în vizuină"
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.WE, RRule.SA, RRule.SU],
    tzid: "UTC",
    dtstart: date(2024, 11, 23),
    until: date(2025, 3, 30),
  })

  events.push(...rruleToEvents(title, rule, true))
}

// {
//   const title = "Căprior (mascul)"
//   const rule = new RRule({
//     freq: RRule.WEEKLY,
//     byweekday: [RRule.WE, RRule.SA, RRule.SU],
//     tzid: "UTC",
//     dtstart: date(2023, 8, 19),
//     until: date(2023, 10, 15),
//   })

//   events.push(...rruleToEvents(title, rule, true))
// }

export default function CalendarulVanatorului({ location }) {
  let siteMetadata = useSiteMetadata()

  return (
    <Layout location={location}>
      <header>
        <h2 className="page-title">
          <Link to={SelfUrl}>{Title}</Link>
        </h2>
        <em>
          Actualizat pentru{" "}
          <a href="/downloads/svpm_ordin_deschidere_2024_2025.pdf">
            sezonul de vânătoare 2024&ndash;2025
          </a>
          . Consultați textul ordinului pentru detalii.
        </em>
      </header>
      <section style={{ paddingTop: "1em" }}>
        <ClientOnly fallback={<p>Loading calendar...</p>}>
          <FullCalendar
            height="auto"
            locale={roLocale}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            validRange={{
              start: minDate,
              end: maxDate,
            }}
          />
        </ClientOnly>
      </section>
      <footer>
        <Share text={`Vezi ${Title}`} url={siteMetadata.siteUrl + SelfUrl} />
      </footer>
    </Layout>
  )
}

export const Head = () => {
  let siteMetadata = useSiteMetadata()
  return <Seo title={Title + " | " + siteMetadata.title} />
}
