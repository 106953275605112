import { Link } from "gatsby"
import React from "react"
import "../styles/index.css"

export default function Layout({ children, location }) {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  let siteTitle
  if (isRootPath) {
    siteTitle = (
      <>
        <h1 className="site-title">
          <Link to="/">
            vanator.md <span className="site-title-duck">🦆</span>
          </Link>
        </h1>
        <em className="site-subtitle">
          Informații esențiale pentru vânătorii din Republica Moldova.
        </em>
      </>
    )
  } else {
    siteTitle = (
      <h2 className="site-title">
        <Link to="/">
          &larr; vanator.md <span className="site-title-duck">🦆</span>
        </Link>
      </h2>
    )
  }

  return (
    <>
      <header className="site-header">{siteTitle}</header>
      <main>{children}</main>
      <hr />
      <footer className="site-footer">
        <p>
          Dacă aveți sugestii, scrieți la{" "}
          <a href="mailto:nv@vanator.md">nv@vanator.md</a>.
        </p>
        <div style={{ textAlign: "right" }}>
          <img
            alt="rață schiță"
            src="/assets/flying-duck.png"
            width="200"
            height="138"
          />
        </div>
      </footer>
    </>
  )
}
