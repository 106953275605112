import React from "react"

// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export default function ClientOnly({ children, fallback, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    if (fallback) {
      return fallback
    } else {
      return null
    }
  }
  return <div {...delegated}>{children}</div>
}
